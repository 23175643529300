import { useRef } from "react";
import Image from "next/image";
import { PauseIcon, PlayIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import type { Media } from "@/types";
import { usePlayerControls } from "@/lib/hooks";
import Link from "next/link";

type Props = {
	data: Media | null;
	altHref?: string;
	className?: string;
	childClassName?: string;
};

export default function MediaCard({ data, altHref, className, childClassName }: Props) {
	if (data === null || !data.url) {
		return <div>No media available</div>;
	}

	if (data.type === "VIDEO") {
		return <VideoPlayer url={data.url} className={className} childClassName={childClassName} />;
	}

	const wrapperClass = cn("flex aspect-video w-full items-center overflow-hidden", className);
	const wrapperChild = (
		<Image
			src={data.url}
			alt=""
			draggable={false}
			width={2048}
			height={1152}
			quality={100}
			className={cn("size-full object-contain", childClassName)}
		/>
	);

	if (altHref !== undefined) {
		return (
			<Link href={altHref} className={wrapperClass}>
				{wrapperChild}
			</Link>
		);
	}

	return <div className={wrapperClass}>{wrapperChild}</div>;
}

function VideoPlayer({
	url,
	className,
	childClassName,
}: Pick<Media, "url"> & Pick<Props, "className" | "childClassName">) {
	const videoRef = useRef<HTMLVideoElement>(null);
	const { paused, toggle } = usePlayerControls(videoRef);

	return (
		<div
			className={cn(
				"relative flex aspect-video w-full items-center overflow-hidden",
				className,
			)}
		>
			<video
				ref={videoRef}
				playsInline
				preload="metadata"
				controls={!paused}
				className={cn("size-full", childClassName)}
			>
				<source src={`${url}#t=0.001`} type="video/mp4" />
			</video>

			<div
				className={cn(
					"absolute inset-0 flex flex-col justify-center transition-opacity z-10",
					!paused && "opacity-0 pointer-events-none",
				)}
			>
				<button
					className="inline-flex size-16 items-center justify-center self-center rounded-full border-4 border-background align-middle text-background"
					onClick={toggle}
				>
					{paused ? (
						<PlayIcon className="ml-1 size-8 fill-current" />
					) : (
						<PauseIcon className="ml-1 size-8 fill-current" />
					)}
				</button>
			</div>
		</div>
	);
}
